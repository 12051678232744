import PropTypes from "prop-types"
import React, { useState } from "react"
import Menu from './menu'



const Header = (props) =>  {

  const [menu, setMenu] = useState( false );


  function openMenu() {
    setMenu(true)
  }

  function closeMenu(data) {
    setMenu(data)
  }


  return (
    <header className="m-header">
        <div className="container">
          <div className="m-header__wrapper">
            <button onClick= { () => openMenu()} className="m-header__button">
              <span></span>
              <span></span> 
            </button>    
          </div>
        </div>
        {menu && 
          <Menu closeMenu={closeMenu } />
        }
        
    </header>

  )
}
  

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
