
import React from 'react';
import { Link } from "gatsby"

import home from "../images/menu/home.jpg"
import ImageIllustrations from '../images/menu/illustrations.jpg'
import ImageTatouages from '../images/menu/tatouages.jpg'
import ImageBiographie from '../images/menu/biographie.jpg'
import ImageContact from '../images/menu/contact.jpg'
import logo from "../images/logo.png"

class Menu extends React.Component  {
    constructor(props) {
        super(props);
        this.state = {
            hover : true,
            thumbnailSelect : '',
            menu : [ 
                {
                name : 'Accueil',
                url : '/',
                show : false,
                thumbnail :  home
                },
                {
                    name : 'Illustrations',
                    url : '/illustrations',
                    show : false,
                    thumbnail :  ImageIllustrations
                },
                {
                    name : 'Tatouages',
                    url : '/tatouages',
                    show : false,
                    thumbnail :  ImageTatouages,
                    faq: true
                },
                {
                    name : 'Biographie',
                    url : '/biographie',
                    show : false,
                    thumbnail :  ImageBiographie
                },
                {
                    name : 'Contact',
                    url : '/contact',
                    show : false,
                    thumbnail :  ImageContact
                },
            ]
        }
    }

    onHover(index) {
        
        let menuCopy =  this.state.menu;
        menuCopy.map( (x , i ) => {
            menuCopy[i].show = (index === i ) ? true : false
        });
        this.setState({
            menu : menuCopy,
            hover: (index !== null) ? true : false,
            thumbnailSelect : (index !== null) ? this.state.menu[index].thumbnail : ''
        }) 
    }

    render() {
        const defaultStyle = {
            opacity:0
        }
        const transitionStyles = {
            opacity:0
        }
          
        return (

            <nav className="m-menu">
                <div className="m-menu__header">
                    <div className="container">
                        <div className="m-menu__header__wrapper">
                            <button onClick= { () => this.props.closeMenu(false) } className="m-menu__header__close">
                                <svg width="13" height="13" xmlns="http://www.w3.org/2000/svg">
                                <path d="M7.3828125 6.2890625l4.9609375 4.9804687c.15625.1432299.234375.3222646.234375.5371094 0 .2148448-.074869.3971347-.2246094.546875-.1497403.1497404-.3320302.2246094-.546875.2246094s-.3938795-.078125-.5371094-.234375L6.2890625 7.3632812 1.30859375 12.34375c-.14322988.15625-.32226455.234375-.53710938.234375-.21484482 0-.39713466-.074869-.546875-.2246094C.07486904 12.2037753 0 12.0214854 0 11.8066406s.07812422-.3938795.234375-.5371094l4.9609375-4.9804687L.234375 1.30859375C.07812422 1.16536387 0 .9863292 0 .77148438c0-.21484483.07486904-.39713467.22460938-.546875C.3743497.07486904.55663955 0 .77148438 0c.21484482 0 .39387949.07812422.53710937.234375L6.2890625 5.1953125 11.2695312.234375C11.4127611.07812422 11.5917958 0 11.8066406 0c.2148448 0 .3971347.07486904.546875.22460938.1497404.14974033.2246094.33203017.2246094.546875 0 .21484482-.078125.39387949-.234375.53710937L7.3828125 6.2890625z"  fillRule="nonzero"/>
                                </svg> 
                                <span>Fermer</span>
                                
                            </button>
                            <img  className="m-menu__header__logo" src={logo} />
                        </div>

                    </div>
                </div>
             
               <div className="container">
                  <div className="m-menu__wrapper">
                      <ul className="m-menu__nav">
                        {
                            this.state.menu.map( (x , index) => {        
                                return (
                                    <li key= {index} className="m-menu__nav__item" >
                                        <Link className="m-menu__nav__item__link" to={x.url} onMouseOver={ () => this.onHover(index)} onMouseLeave={ () => this.onHover(null)} >
                                            {x.name}
                                        
                                        </Link>
                                        {x.faq && 
                                            <Link className="m-menu__nav__item__sublink" to={'/faqs'}  >
                                                Questions fréquentes
                                    
                                            </Link>
                                        }
                                        
                                        {x.show &&
                                            <p  className="m-menu__nav__item__text" >{x.name}</p>
                                            
                                        } 
                                    </li>                   
                                )
                            })
                        }
                      </ul>
                      {
                          this.state.hover && 
                          <div className="m-menu__picture">
                              <img src={this.state.thumbnailSelect} />
                          </div>
                      }
                   
                  </div>
              </div>
             
          </nav>
      
        )
    }
   
}
export default Menu
